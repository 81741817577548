








































































































































































































































































































































































import { vxm } from "@/store";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { cilBriefcase, cilHouse } from "@coreui/icons";
import { CIcon } from "@coreui/vue";


import ProfileCard from "@shared_vue/components/viewcomponents/ProfileCard.vue"
import CustomCard from "@shared_vue/components/viewcomponents/CustomCard.vue"

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Axios from "axios";
import DynamicInputModal from "@shared_vue/components/modals/DynamicInputModal.vue";

import TrueIDCard from "@shared_vue/components/viewcomponents/TrueIDCard.vue";
import SmallCard from "@shared_vue/components/viewcomponents/SmallCard.vue";
import TinyCard from "@shared_vue/components/viewcomponents/TinyCard.vue";
import FormButton from "@shared_vue/components/viewcomponents/FormButton.vue";
import ContactCard from "@shared_vue/components/viewcomponents/ContactCard.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import {
  DocumentDTO,
  FileApiFactory, ProfileApiFactory, Status, Subscription

} from "@shared_vue/openapi/myvaultapi/api";
import {Configuration} from "@shared_vue/openapi/myvaultapi/configuration";
import {
  AdminApiFactory, CaseApiFactory, CompanyDTO, ContactRelationship, ErpcDTO, ProductDTO,
  ProfileDTO, RevenueCatResponse, SubOptions, SubscriptionOption, TbxSubDto, UpdateBranchDTO,
} from "@shared_vue/openapi/caseapi";
import {AddressDTO, ContactDTO, ContactType} from "@shared_vue/openapi/caseapi/api";
import MyProduct from "@shared_vue/components/viewcomponents/MyProduct.vue";
import { OptionDTO } from "../../../../shared_vue/openapi/myvaultapi";
import { SelectOption } from "@/types/ExtraTypes";
import QrcodeVue from 'qrcode.vue'

@Component({
  components: {
    TinyCard,
    TrueIDCard,
    FormButton,
    ContactCard,
    CIcon,
    WaitModal,
    CustomCard,
    ProfileCard,
    SmallCard,
    Cropper,
    DynamicInputModal,
    MyProduct,
    QrcodeVue,
  },
})
export default class ErpcConnectMe extends Vue {
  @Prop(String) readonly id: string|undefined;
  private auth = vxm.auth;
  private ui = vxm.ui;
  private icon1 = cilHouse;
  private link: string = "";
  private icon2 = cilBriefcase;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private caseApi = CaseApiFactory(<Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL }, process.env.VUE_APP_CASE_API_URL)
  private profileApi2 = ProfileApiFactory(
      <Configuration>{ basePath: process.env.VUE_APP_MYVAULT_API_URL },
      process.env.VUE_APP_MYVAULT_API_URL
  );
  private showNewDocument1: boolean = false;
  private showNewDocument2: boolean = false;
  private newName : string = ''
  private newCat: any = 0;
  private showSuccessModal: boolean = false;
  private newFile: any ="";
  private newDescription: string = '';
  private documentList : DocumentDTO[] = []
  
  private showWait: boolean = false;
  private loadingData: boolean = true;
  private dob: string = "09 Sept 1979";
  erpc: ErpcDTO = <ErpcDTO>{};
  
  private editMode: boolean = false;
  private editBasics: boolean = false;
  private addressClicked: boolean = false;
  private field1: string = "";
  private field2: string = "";
  private image: any = null;
  private productList: ProductDTO[] = [];
  private companyList: CompanyDTO[] = [];
  private branchList: ErpcDTO[] = [];
  private homestreet: string = "";
  private homecity: string = "";
  private homestate: string = "";
  private homecounty: string = "";
  private homestate_abbr: string = "";
  private homezip: string = "";
  private homecountry: string = "";
  private homelatitude: string = "";
  private homelongitude: string = "";
  private genderPopup: boolean = false;

  private addressTypes: OptionDTO[] = [];
  private provinceOptions: OptionDTO[] = [];
  private genderTypeOptions: any = [];
  private languageOptions: any = [];
  private provinces: any = [];
  private addressTypeOptions: any = [];

  private logo: string|null = null;
  private showAddressModal: boolean = false;
  private showSelfieModal: boolean = false;

  
  get branchId(): number| undefined {
    if (this.id){
      return parseInt(this.id);
    }
  }

  private cancel(){
    this.showNewDocument1=false;
    this.showNewDocument2=false;
    this.newDescription='';
    this.newName='';
    this.newCat=null;
    this.showSuccessModal=false;
  }

  public showSuccess(){
    this.showSuccessModal=true;
  }

  private fetchAllDocument(){
    this.showWait=true;
    this.caseApi.caseListBranchFiles(this.branchId!).then(resp => {
      console.log(resp.data);
      //add version stuff here :-O
      this.documentList=resp.data;
    })
        .catch(err => {
          // Handle Error Here
          console.error(err);
        }).finally(()=>{
      this.showWait=false;
    });
  }
  private downloadDocument(id:number){
    this.caseApi.caseGetBranchFileLinkById(this.branchId!, id).then((link)=>{
      
      window.open(link.data, '_blank');
    })
  }

  private next1(){
    // this.showNewDocument1=false;
    // this.showNewDocument2=true;
    console.log("next1")
    let formData = new FormData();
    formData.append('file', this.newFile);
    formData.append('description', this.newDescription)
    formData.append('branch_id', this.branchId!.toString())
    const outerthis = this;
    //move to service
    Axios.post(process.env.VUE_APP_CASE_API_URL + '/Case/AddErpcMedia', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          console.log(response.status);
          if (response.status==200){
            outerthis.cancel();
            outerthis.showSuccess();
            outerthis.fetchAllDocument();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  }
  
  handleFileUpload(filez: any, eo: any){
    console.log('handlefileupload')
    this.newFile=filez[0];
  }
  private addresspopup() {
    this.showAddressModal=true;
  }

  private mapAddressTypes(addressOptions: OptionDTO[]){
    this.addressTypes=addressOptions;
    this.addressTypeOptions=addressOptions.map(o=>({value:o.id,label:o.text}));
  }

  private mapProvinceTypes(provinceOptions: OptionDTO[]){
    this.provinceOptions=provinceOptions;
    this.provinces=provinceOptions.map(o=>({value:o.id,label:o.text}));
  }
  

  private subsCore: Array<SubscriptionOption> = []
  private myTbxSubs: Array<TbxSubDto> = []
  private revenueCatSubs: Array<string> = []
  private waitABit = false;
  private selectedOption: any=[0];
  private addressName: string = "";

  get subNamesList(): string[] {
    return this.subsCore?.map(s=>s.name);
  }

  private clearAddressPopup() {
    this.showAddressModal=false;
    this.field1='';
    this.homestreet='';
    this.homestate='';
    this.homecountry='';
    this.homecity='';
    this.homezip='';
    this.homelatitude='';
    this.homelongitude='';
    this.homestate_abbr='';
    this.addressName="";
  }
  //this data will be fetched by web service and cached
  private reset() {
    this.image = null;
    //@ts-ignore
    this.$refs.file.value=null;
    this.showSelfieModal = false;
  }
  
  uploadCropImage() {
    // @ts-ignore
    const { canvas } = this.$refs.cropper.getResult();
    console.log('let us do this, sir')
    const outerthis = this;
    if (canvas) {
      canvas.toBlob((blob: any) => {
        let formData = new FormData();
        
        formData.append('file', blob);
        formData.append('description', 'logo')
        formData.append('branch_id', this.branchId!.toString())
        Axios.post(process.env.VUE_APP_CASE_API_URL + '/case/AddErpcMedia', formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(function (response) {
              console.log(response.status);
              if (response.status==200){
                outerthis.reset();
                // outerthis.showSuccess();
                outerthis.fetchAll();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      })
    }
  }

  private loadImage(event: any) {
    // Reference to the DOM input element
    console.log('load image')
    let input = event.target;
    // Ensure that you have a file before attempting to read it
    if (input.files && input.files[0]) {
      console.log('got file')
      // create a new FileReader to read this image and convert to base64 format
      let reader = new FileReader();
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e) => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData
        console.log(e)
        this.image = e.target?.result;
      };
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(input.files[0]);
    }
  }

  @Watch('auth.idToken')
  onIdToken(val: boolean, oldval: boolean) {
    console.log('onidtoken')
    if (this.waitABit){
      this.waitABit=false;
      this.fetchAll();
    }
  }

  @Watch('branchOptions')
  onBranchFilter(val: [], oldval: []) {
    console.log('onbranchfilter')
    if (this.loadingData) {
      return;
    }
    if (val!=oldval){
      // @ts-ignore
      // this.$refs.branchselect.value=val[0].value;

      if (val&&val.length>0) {
        // @ts-ignore
        this.selectedBranch = val[0].value;
      }
    }
  }

  // private async getCompanies(){
  //   let allCompanies = await this.caseApi.caseGetCompanies();
  //   let allBranches = await this.caseApi.caseGetBranches();
  //   this.companyList = [{id:0,name:'PLEASE SELECT'}].concat(allCompanies.data);
  //   this.branchList = allBranches.data;
  //
  // }
  
  private async fetchAll(){ // maybe rather do this on app load than on page load?
    this.loadingData=true;
    try {
      await this.fetchOptions('AddressType', this.mapAddressTypes);
      await this.fetchOptions('Province', this.mapProvinceTypes); //I'm sure there is a more generic way to do this
      await this.fetchOptions('AddressType', this.mapAddressTypes);
      // await this.getCompanies();
      const branchData = await this.caseApi.caseGetBranch(this.branchId!);
      this.erpc = branchData.data;
    } finally {
      this.loadingData=false;
      this.showWait=false;
    }
  }
  private goEditMode(){
    this.editMode=!this.editMode;
    if (!this.editMode){
      this.showWait = true;
      try {
        this.updateProfile();
      } finally {
        this.showWait = false;
      }
    }
  }

  private goEditBasics(){
    this.editBasics=!this.editBasics;
  }

  private changeGender(){
    this.genderPopup=true;
  }

  get erpcOptions() {
    return [
      {
        value:"",label:"Choose..."
      }
    ]
  }
  
  private submitAddress(){
    const outerThis = this;
    console.log('submitting address')
    this.showAddressModal=false;
    let newAddress = <AddressDTO>{};
    /*
    Id?: number;
    AddressType?: string;
    Name?: string;
    UnitNumber?: string | null;
    ComplexName?: string | null;
    StreetNumber?: string;
    StreetName?: string;
    Suburb?: string;
    City?: string;
    Province?: string;
    Country?: string;
    PostalCode?: string;
    IsPrimary?: boolean;
    Confirmed?: boolean;
     */
    newAddress.city=this.homecity;
    newAddress.suburb=this.homecounty;
    newAddress.country=this.homecountry;
    newAddress.isPrimary=this.selectedOption==1;
    newAddress.name=this.addressName;
    newAddress.postalCode=this.homezip;
    newAddress.provinceId=this.homestate_abbr;
    newAddress.addressTypeId=this.selectedOption;
    if (this.selectedOption==="Primary"){
      newAddress.isPrimary=true;
    }
    newAddress.streetName=this.homestreet;
    newAddress.latitude=this.homelatitude.toString();
    newAddress.longitude=this.homelongitude.toString();
    console.log(newAddress);
    this.showWait=true;
    this.caseApi.caseAddAddressToBranch(this.branchId!, newAddress).then(res=>{
      this.fetchAll();
    }).catch(err=>{

    }).finally(()=>{
      outerThis.clearAddressPopup();
      outerThis.showWait=false;
    })
  }

  private async fetchOptions(which: string, resultProcessor: Function) {
    let response = await this.profileApi2.profileGetOptions(which)
    console.log(response.data)
    resultProcessor(response.data);
  }

  get selfie(){
    if (this.documentList && this.documentList.length>0) {
      if (this.documentList.find(d=>d.filename=="logo.png")) {
        return this.documentList.find(d => d.filename == "logo.png")!.location;
      } else {
        return this.publicPath + '/images/profile-dummy3-300x300.png';
      }
    }
    else {
      return this.publicPath + '/images/profile-dummy3-300x300.png';
    }

  }

  

  private async updateProfile() {
    let result = await this.caseApi.caseUpsertBranch(<ErpcDTO>this.erpc);
    if (result.status==200) { 
      this.erpc = result.data
      this.editBasics=false;
    }
  }

  private get primaryAddressCompact(){
    
    if (this.erpc&&this.erpc.address){
      
        return `${this.erpc.address.streetName}, ${this.erpc.address.suburb}, ${this.erpc.address.city}, ${this.erpc.address.postalCode}, ${this.erpc.address.country}`
    }
    return '';
  }
  
  

  private get getBio(){
    if (this.erpc && this.erpc.bio){
      return this.erpc.bio;
    } else {
      return "Enter some basic information"
    }
  }
  
  private newInput(eventdata:any){
    console.log('newinput')
    console.log(eventdata)
    this.field1=eventdata;
    this.addressClicked = true;
  }
  mounted() {
    this.showWait = true;
    const outerThis = this;
    this.link = `https://apiuser.gen11project.com/usermanager/SaveQRLink/${this.branchId}`;
    this.fetchAllDocument();
    //hey don't forget a nice plz wait modal
    //do we want reminders in the store? I'm not really sure
    //wait for valid user if this was refreshed (I do not save token in store)
    if (!vxm.auth.idToken){ //wait a bit in case it is coming
      this.waitABit=true;
    } else {
      this.fetchAll();
    }
  }
}
